import _export from "../internals/export";
import _objectToArray from "../internals/object-to-array";
var $ = _export;
var $entries = _objectToArray.entries;

// `Object.entries` method
// https://tc39.es/ecma262/#sec-object.entries
$({
  target: "Object",
  stat: true
}, {
  entries: function entries(O) {
    return $entries(O);
  }
});
export default {};